import React, { useState } from "react"
import { useLocation } from "@reach/router"

import SEO from "../components/seo"

import Logo2 from "../assets/static/tuteur.png"

import "../assets/css/A.post-25.css,qx68393.pagespeed.cf.JaKSKwofFr.css"
import "../assets/css/frontend.min.css,qx68393.pagespeed.ce.EjDU7T1mTD.css"
import "../assets/css/all.css"

const PriorityPage = () => {
  const ZapierURL = "https://hooks.zapier.com/hooks/catch/6841944/oxb1qpc/"
  const [prenomEleve, setPrenomEleve] = useState("")
  const [nbHeures, setNbHeures] = useState(0)
  const [dateDebut, setDateDebut] = useState("")
  const [urgence, setUrgence] = useState("")
  let email = new URLSearchParams(useLocation().search).get("email")
  if (email && email.slice(email.length - 1) === "/")
    email = email.substr(0, email.length - 1)

  const postFormToZapier = () => {
    const body = {
      prenomEleve,
      nbHeures,
      dateDebut,
      urgence,
      email,
    }
    fetch(ZapierURL, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    }).then(
      () => (document.location = document.location.origin + `/remerciements/`)
    )
  }



  return (
    <>
      <SEO title="Tuteur à l'Heure " />
      <div
        data-server-rendered="true"
        id="app"
        className="layout landing no-progressbar step-step-cible auto-continue"
      >
        <div
          className="steps-zone"
          style={{
            padding: "0px",
          }}
        >
          <div
            className="steps-content"
            style={{
              padding: "0px",
              margin: "0px",
              width: "100%",
            }}
          >
            <div
              className="secondary-zone"
              style={{
                backgroundColor: "#3C9BEC",
                color: "white",
                padding: "2.5rem",
              }}
            >
              <div className="secondary-zone-content">
                <div className="primary-body" style={{ textAlign: "center" }}>
                  <a href="https://www.tuteuralheure.fr/">
                    <img
                      src={Logo2}
                      alt="Cours accompagnement scolaire Tuteur à l'heure"
                      width={510}
                      height={258}
                    />
                  </a>
                </div>
                <div className="secondary-body">
                  <div className="base-block-type-wysiwyg hidden-xs">
                    <h1 style={{ textAlign: "center", color: "#FFF26E" }}>
                      Nous répondons à vos questions !
                    </h1>
                    <ul>
                      <li>
                        <h6>
                          <br />•
                          <span style={{ color: "#FFF26E" }}>
                            <strong>Aucun frais de dossier</strong>
                          </span>{" "}
                          : vous ne payez que les cours (de façon hebdomadaire).
                        </h6>
                      </li>
                      <li>
                        <h6>
                          <br />•
                          <span style={{ color: "#FFF26E" }}>
                            <strong>Vous souhaitez arrêter les cours ?</strong>
                          </span>{" "}
                          Aucun problème, il n'y a pas d'engagement ni
                          d'abonnement.
                        </h6>
                      </li>
                      <li>
                        <h6>
                          <br />• Les cours sont dispensés par&nbsp;
                          <span style={{ color: "#FFF26E" }}>
                            <strong>le même tuteur à chaque fois</strong>
                          </span>
                          , peu importe votre demande &rarr; nous trouvons la
                          perle rare pour chaque élève.
                        </h6>
                      </li>
                      <li>
                        <h6>
                          <br />• Nos tuteurs sont patients et ont apporté&nbsp;
                          <span style={{ color: "#FFF26E" }}>
                            <strong>
                              méthodologie, confiance et organisation
                            </strong>
                          </span>
                          &nbsp;à nos 450 élèves.&nbsp;
                        </h6>
                      </li>
                      <li>
                        <h6>
                          <br />• Les cours peuvent être effectués par&nbsp;
                          <span style={{ color: "#FFF26E" }}>
                            <strong>tranche de 15 minutes</strong>
                          </span>{" "}
                          à compter d'une heure de cours.
                        </h6>
                      </li>
                      <li>
                        <h6>
                          <br />• Un accompagnement{" "}
                          <span style={{ color: "#FFF26E" }}>
                            <strong>scolaire</strong>
                          </span>{" "}
                          et{" "}
                          <span style={{ color: "#FFF26E" }}>
                            <strong>psychologique</strong>
                          </span>
                          &nbsp;afin de mieux connaître l'étudiant pour&nbsp;
                          <span style={{ color: "#FFF26E" }}>
                            <strong>l'aider de la meilleure façon</strong>
                          </span>
                        </h6>
                      </li>
                      <li>
                        <h6>
                          <br />• Nos tuteurs sont recrutés dans les plus
                          grandes écoles, sur&nbsp;
                          <span style={{ color: "#FFF26E" }}>
                            <strong>dossier académique ET pédagogique.</strong>
                          </span>
                        </h6>
                      </li>
                      <li>
                        <h6>
                          <br />• Nous suivons nos élèves sur toute la durée des
                          cours pour&nbsp;
                          <span style={{ color: "#FFF26E" }}>
                            <strong>nous assurer de leur réussite.</strong>
                          </span>
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="primary-zone">
              <div className="primary-zone-content">
                <div className="primary-body">
                  <form className="form-generator">
                    <div className="form-generator-field field-html-wrapper field-wide ">
                      <div>
                        <div
                          style={{
                            fontSize: "20px",
                            marginLeft: "30px",
                            marginRight: "30px",
                          }}
                        >
                          <h1>
                            Obtenez un{" "}
                            <span style={{ color: "#4A25AA" }}>
                              <u>devis instantané</u>
                            </span>{" "}
                            sur le prix des cours en remplissant ce formulaire
                          </h1>
                        </div>
                      </div>
                      {/**/}
                    </div>
                    <table style={{ marginLeft: "2em", marginTop: "2em", textAlign: "center", borderCollapse: "collapse" }}>
                      <tr style={{
                        border: "3px solid var(--AkMajorBlue)",
                        borderBottom: "3px solid #f4eb72"
                      }}
                      >
                        <td style={{
                          borderRight: "3px solid var(--AkMajorBlue)",
                          padding: "0.5em",
                          verticalAlign: "middle"
                        }}
                        ><span style={{ fontSize: "120%" }}>Cours à domicile</span></td>
                        <td style={{
                          borderRight: "3px solid var(--AkMajorBlue)",
                          padding: "1em",
                          verticalAlign: "middle"
                        }}>€ 35/h</td>
                        <td rowspan="2" style={{
                          border: "3px solid var(--AkMajorBlue)",
                          padding: "0.5em",
                          verticalAlign: "middle"
                        }}><span style={{ fontSize: "200%" }}>➡</span><br></br><span style={{ color: "red" }}>
                            Réduction après crédit d'impôt de 50%</span>
                          <br></br><span style={{ fontSize: "200%" }}>➡</span>
                        </td>
                        <td style={{
                          borderRight: "3px solid var(--AkMajorBlue)",
                          padding: "0.5em",
                          fontSize: "150%",
                          color: "#4A25AA"
                        }}>€ 17,50/h<br></br><span style={{ color: "red", fontSize: "60%" }}>Après crédit d'impôt</span></td>
                      </tr>
                      <tr style={{
                        border: "3px solid var(--AkMajorBlue)",
                      }}
                      >
                        <td style={{
                          borderRight: "3px solid var(--AkMajorBlue)",
                          padding: "0.5em",
                          verticalAlign: "middle"
                        }}
                        ><span style={{ fontSize: "120%" }}>Cours en ligne</span></td>
                        <td style={{
                          borderRight: "3px solid var(--AkMajorBlue)",
                          padding: "1em",
                          verticalAlign: "middle"
                        }}>€ 30/h</td>
                        <td style={{
                          borderRight: "3px solid var(--AkMajorBlue)",
                          padding: "0.5em",
                          fontSize: "150%",
                          color: "#4A25AA"
                        }}>€ 15/h<br></br><span style={{ color: "red", fontSize: "60%" }}>Après crédit d'impôt</span></td>
                      </tr>
                    </table>
                    <div className="form-generator-field field-input-wrapper">
                      <div className="field-input base-input field-input-text focused">
                        <label htmlFor="input0.23383229187677923">
                          <span className="label-text">Prénom de l'élève</span>
                        </label>{" "}
                        <div className="input-wrapper">
                          <input
                            spellCheck="false"
                            autoComplete="new-password"
                            type="text"
                            id="input0.23383229187677923"
                            name="firstNameStudent"
                            placeholder="Saisissez le prénom de l'élève"
                            onChange={e => setPrenomEleve(e.target.value)}
                            required
                          />
                        </div>{" "}
                        {/**/}
                      </div>{" "}
                      {/**/}
                    </div>
                    <div className="form-generator-field field-phone-number-wrapper">
                      <div className="field-phone-number base-input focused">
                        <label htmlFor="phone-number0.18234676967425578">
                          <span className="label-text">
                            Nombre d'heures de cours par semaine
                          </span>
                        </label>{" "}
                        <div className="input-wrapper">
                          <input
                            type="number"
                            autoComplete="on"
                            id="phone-number0.18234676967425578"
                            maxLength={25}
                            name="nbHeures"
                            placeholder="Saisissez le nombre d'heures de cours"
                            tabIndex={0}
                            className="vti__input"
                            onChange={e => setNbHeures(e.target.value)}
                            required
                          />
                        </div>{" "}
                        {/**/}
                      </div>{" "}
                      {/**/}
                    </div>
                    <div className="form-generator-field field-input-wrapper">
                      <div className="field-input base-input field-input-text focused">
                        <label htmlFor="input0.23383229187677923">
                          <span className="label-text">
                            Date de début des cours
                          </span>
                        </label>{" "}
                        <div className="input-wrapper">
                          <input
                            spellCheck="false"
                            autoComplete="new-password"
                            type="date"
                            id="input0.23383229187677923"
                            name="date"
                            required
                            placeholder="Saisissez une date de début"
                            onChange={e => setDateDebut(e.target.value)}
                          />
                        </div>{" "}
                        {/**/}
                      </div>{" "}
                      {/**/}
                    </div>
                    <div className="form-generator-field field-input-wrapper">
                      <div className="field-input base-input field-input-select focused">
                        <label htmlFor="input0.23383229187677923">
                          <span className="label-text">
                            Urgence de la demande
                          </span>
                        </label>{" "}
                        <div className="input-wrapper">
                          <div class="select">
                            <select
                              name="urgence"
                              onChange={e => setUrgence(e.target.value)}
                            >
                              <option value="">-- Choississez une option --</option>
                              <option style={{ backgroundColor: '#FF0000' }}
                                value="
                              Décrochage ou échec scolaire - il faut un soutien
                              intense en urgence
                            "
                              >
                                Décrochage ou échec scolaire - il faut un soutien
                                intense en urgence
                            </option>
                              <option style={{ backgroundColor: '#FFA500' }}
                                value="
                              En difficulté - il faut remonter la pente pour la
                              fin d'année
                            "
                              >
                                En difficulté - il faut remonter la pente pour la
                                fin d'année
                            </option>
                              <option style={{ backgroundColor: '#FFFF00' }}
                                value="
                              Bon élève - pour atteindre le 15 de moyenne
                            "
                              >
                                Bon élève - pour atteindre le 15 de moyenne
                            </option>
                              <option style={{ backgroundColor: '#ADFF2F' }}
                                value="
                              Perfectionnement - préparation concours et examens
                            "
                              >
                                Perfectionnement - préparation concours et examens
                            </option>
                            </select>
                          </div>{" "}
                          {/**/}
                        </div>
                      </div>{" "}
                      {/**/}
                    </div>
                    <div className="form-generator-field field-input-wrapper">
                      <div
                        role="button"
                        className="base-button base-button-solid"
                        onClick={
                          prenomEleve &&
                          nbHeures && nbHeures > 0 &&
                          dateDebut && Date.parse(dateDebut) > Date.now() &&
                          urgence &&
                          postFormToZapier
                        }
                      >
                        Envoyer
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div
            className="bottom-blocks hidden-lg"
            style={{ backgroundColor: "#3C9BEC" }}
          >
            <div className="padded-content">
              <div className="flex-wrapper">
                <div className="base-block-type-wysiwyg">
                  <h1 style={{ textAlign: "center", color: "#FFF26E" }}>
                    Nous répondons à vos questions !
                  </h1>
                  <ul>
                    <li>
                      <h6>
                        <br />•
                        <span style={{ color: "#FFF26E" }}>
                          <strong>Aucun frais de dossier</strong>
                        </span>{" "}
                        : vous ne payez que les cours (de façon hebdomadaire).
                      </h6>
                    </li>
                    <li>
                      <h6>
                        <br />•
                        <span style={{ color: "#FFF26E" }}>
                          <strong>Vous souhaitez arrêter les cours ?</strong>
                        </span>{" "}
                        Aucun problème, il n'y a pas d'engagement ni
                        d'abonnement.
                      </h6>
                    </li>
                    <li>
                      <h6>
                        <br />• Les cours sont dispensés par&nbsp;
                        <span style={{ color: "#FFF26E" }}>
                          <strong>le même tuteur à chaque fois</strong>
                        </span>
                        , peu importe votre demande &rarr; nous trouvons la
                        perle rare pour chaque élève.
                      </h6>
                    </li>
                    <li>
                      <h6>
                        <br />• Nos tuteurs sont patients et ont apporté&nbsp;
                        <span style={{ color: "#FFF26E" }}>
                          <strong>
                            méthodologie, confiance et organisation
                          </strong>
                        </span>
                        &nbsp;à nos 450 élèves.&nbsp;
                      </h6>
                    </li>
                    <li>
                      <h6>
                        <br />• Les cours peuvent être effectués par&nbsp;
                        <span style={{ color: "#FFF26E" }}>
                          <strong>tranche de 15 minutes</strong>
                        </span>{" "}
                        à compter d'une heure de cours.
                      </h6>
                    </li>
                    <li>
                      <h6>
                        <br />• Un accompagnement{" "}
                        <span style={{ color: "#FFF26E" }}>
                          <strong>scolaire</strong>
                        </span>{" "}
                        et{" "}
                        <span style={{ color: "#FFF26E" }}>
                          <strong>psychologique</strong>
                        </span>
                        &nbsp;afin de mieux connaître l'étudiant pour&nbsp;
                        <span style={{ color: "#FFF26E" }}>
                          <strong>l'aider de la meilleure façon</strong>
                        </span>
                      </h6>
                    </li>
                    <li>
                      <h6>
                        <br />• Nos tuteurs sont recrutés dans les plus grandes
                        écoles, sur&nbsp;
                        <span style={{ color: "#FFF26E" }}>
                          <strong>dossier académique ET pédagogique.</strong>
                        </span>
                      </h6>
                    </li>
                    <li>
                      <h6>
                        <br />• Nous suivons nos élèves sur toute la durée des
                        cours pour&nbsp;
                        <span style={{ color: "#FFF26E" }}>
                          <strong>nous assurer de leur réussite.</strong>
                        </span>
                      </h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PriorityPage
